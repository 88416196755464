import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { MoveBoardDirective } from "@/ts/business/game/controller/board/MoveBoardDirective";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the dice.
 */
export class QuickMovesBoardDirective extends BoardDirective {
    readonly moves: MoveBoardDirective[];

    constructor(
        directiveID: number,
        rules: RuleSet,
        moves: MoveBoardDirective[],
    ) {
        super(directiveID, rules, moves[0].moveIndex);
        this.moves = moves;
    }

    isLimboDirective(): boolean {
        return false;
    }

    override getBoard(): Board {
        throw new Error("Quick moves board directive does not have a board");
    }

    override getActivePlayer(): PlayerType {
        throw new Error("Quick moves board directive does not have an active player");
    }
}
