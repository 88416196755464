import { Controller } from "@/ts/business/game/controller/Controller";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerStateDirective } from "./PlayerStateDirective";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * Controls the rendering directives to give to the dice.
 */
export class PlayerStateController extends Controller<PlayerStateDirective> {
    override setup(): () => void {
        return () => {};
    }

    override onDiscardedDirectives(_directives: PlayerStateDirective[]): void {
        // Nothing to do.
    }

    createUpdate(
        lightPlayerState: PlayerState,
        darkPlayerState: PlayerState,
        activePlayer: PlayerType,
    ): PlayerStateDirective[] {

        return [new PlayerStateDirective(
            this.assignNextDirectiveID(),
            lightPlayerState,
            darkPlayerState,
            activePlayer,
        )];
    }
}
