import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerNo } from "@/ts/business/game/PlayerNo";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { RemoteLobbyStatus } from "@/ts/business/game/controller/status/RemoteLobbyStatus";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";


export class AnalysisLobbyStatus extends LobbyStatus {
    private readonly settings: KnownLobbySettings;
    private readonly endReason: GameEndReason;
    private readonly endingPlayer: PlayerType | null;
    private readonly completeGameID: string;

    constructor(
        settings: KnownLobbySettings,
        endReason: GameEndReason,
        endingPlayer: PlayerType | null,
        completeGameID: string,
    ) {
        super();
        this.settings = settings;
        this.endReason = endReason;
        this.endingPlayer = endingPlayer;
        this.completeGameID = completeGameID;
    }

    override isRemote(): this is RemoteLobbyStatus {
        return false;
    }

    override getLobbyID(): string | null {
        return null;
    }

    override hasEverConnected(): boolean {
        return true;
    }

    override isConnected(): boolean {
        return true;
    }

    override isGameAvailable(): boolean {
        return true;
    }

    override isGameUnknown(): boolean {
        return false;
    }

    override getGameMode(): GameMode {
        return this.settings.getMode();
    }

    override getBotType(): BotType | null {
        const mode = this.settings.getMode();
        return (mode === GameMode.COMPUTER ? this.settings.getBotType() : null);
    }

    override getEndReason(): GameEndReason {
        return this.endReason;
    }

    override getEndingPlayer(): PlayerType | null {
        return this.endingPlayer;
    }

    override hasPingTimes(): boolean {
        return false;
    }

    override getPlayer1LastPingTimeMs(): number {
        return 0;
    }

    override getPlayer2LastPingTimeMs(): number {
        return 0;
    }

    override getCompleteGameID(): string {
        return this.completeGameID;
    }

    override withCompleteGameID(_completeGameID: string | null): AnalysisLobbyStatus {
        throw new Error("AnalysisLobbyStatus does not support withCompleteGameID");
    }

    override withEnd(_abandonReason: GameEndReason, _abandoner: PlayerType): AnalysisLobbyStatus {
        throw new Error("AnalysisLobbyStatus does not support withEnd");
    }

    override getRematchRequestedBy(): PlayerNo | null {
        return null;
    }
}
