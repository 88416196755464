import { PlayerStateController } from "@/ts/business/game/controller/playerstate/PlayerStateController";
import { DiceController } from "@/ts/business/game/controller/dice/DiceController";
import { BoardController, QuickMove } from "@/ts/business/game/controller/board/BoardController";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { Game } from "@/ts/royalur/Game";
import { ReactionController } from "@/ts/business/game/controller/reactions/ReactionController";
import { GameController } from "@/ts/business/game/controller/GameController";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { GameDirective } from "@/ts/business/game/controller/GameDirective";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Rune } from "@/ts/business/Rune";
import { clamp } from "@/ts/util/numbers";
import { NavigationGameEvent } from "@/ts/business/game/event/NavigationGameEvent";
import { MoveInfo } from "@/ts/business/analysis/MoveInfo";
import { MoveAnalysis } from "@/ts/business/analysis/MoveAnalysis";
import { GameAnalysis } from "@/ts/business/analysis/GameAnalysis";
import { MoveCategory } from "@/ts/business/analysis/MoveCategory";
import { MoveHighlight } from "@/ts/business/game/controller/board/AnalysisBoardDirective";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";


/**
 * Coordinates the interactions within the analysis of a game.
 */
export class AnalysisGameController extends GameController {
    private game: Game;
    private moves: MoveInfo[] = [];
    private gameAnalysis: GameAnalysis | null = null;

    private readonly moveIndex: Rune<number>;
    private readonly analysedMoves: Rune<MoveAnalysis[]>;

    constructor(
        playerStateController: PlayerStateController,
        reactionController: ReactionController,
        diceController: DiceController,
        boardController: BoardController,
        source: GameSource<any>,
        defaultThemeType: GameThemeType,
    ) {
        super(
            playerStateController,
            reactionController,
            diceController,
            boardController,
            source,
            defaultThemeType,
        );

        this.game = source.game.get();
        this.moveIndex = new Rune<number>(-1);
        this.analysedMoves = new Rune<MoveAnalysis[]>([]);
        this.init(this.game);

        this.postConstructor();
    }

    override setup(): () => void {
        const cleanup: (() => void)[] = [
            super.setup(),
        ];

        cleanup.push(this.source.game.subscribe(
            game => this.init(game),
        ));

        cleanup.push(this.boardController.addNavigationListener(
            e => this.processNavigation(e)
        ));
        return () => cleanup.forEach(fn => fn());
    }

    private init(game: Game) {
        this.game = game;
        this.moves = MoveInfo.listMoves(this.game);
        this.updateGameAnalysis(this.gameAnalysis, true);
        this.moveIndex.set(-1);
        this.pushDirectives(this.generateGameDirectives(-1, []));
    }

    getMoves(): MoveInfo[] {
        return this.moves;
    }

    getAnalysedMoves(): Rune<MoveAnalysis[]> {
        return this.analysedMoves;
    }

    getFocusedPlayer(): PlayerType | null {
        return this.getPlayers().get().getYourPlayer();
    }

    updateGameAnalysis(analysis: GameAnalysis | null, forceReset?: boolean) {
        if (!forceReset && analysis === this.gameAnalysis)
            return;

        this.gameAnalysis = analysis;

        const rawMoveAnalyses = analysis?.moveAnalyses ?? [];
        let moveAnalyses = rawMoveAnalyses;

        // End the review at the second key move when analysis is limited.
        if (analysis && analysis.hasBeenLimited && rawMoveAnalyses.length > 0) {
            const player = this.getFocusedPlayer();
            if (player) {
                moveAnalyses = [];

                let seenKeyMoves = 0;
                for (const move of rawMoveAnalyses) {
                    if (move.isKeyMove && move.player === player) {
                        moveAnalyses.push(move);
                        seenKeyMoves += 1;
                        if (seenKeyMoves >= 2)
                            break;

                    } else {
                        moveAnalyses.push(move.withoutMoveDesc());
                    }
                }
            }
        }
        this.analysedMoves.set(moveAnalyses);
    }

    getDisplayMove(): MoveInfo | null {
        return this.moves[this.moveIndex.get()] ?? null;
    }

    generateGameDirective(
        moveIndex: number,
        state: GameState,
        rollState: RolledGameState | null,
        moves: QuickMove[],
    ): GameDirective {
        const subject = state.getSubject() ?? PlayerType.LIGHT;

        const boardDirectives: BoardDirective[] = [];
        const diceDirectives: DiceDirective[] = [];
        const playerStateDirectives: PlayerStateDirective[] = [];

        // Create the dice directive.
        if (moves.length > 0) {
            diceDirectives.push(...this.diceController.createHidden());

        } else {
            let roll: RoyalUrRoll;
            if (state instanceof RolledGameState
                || state instanceof WaitingForMoveGameState
                || state instanceof MovedGameState) {

                roll = RoyalUrRoll.cast(state.getRoll());
            } else if (rollState) {
                roll = RoyalUrRoll.cast(rollState.getRoll());
            } else {
                roll = RoyalUrRoll.createZeroed(4);
            }
            diceDirectives.push(...this.diceController.createRolled(
                subject, roll,
            ));
        }

        // Create the board directive.
        if (moves.length > 0) {
            boardDirectives.push(...this.boardController.createQuickMoves(
                this.source.getRules(), moves,
            ));
        } else if (state instanceof MovedGameState) {
            const move = this.analysedMoves.get()[moveIndex];
            const moveHighlights: MoveHighlight[] = [];
            if (move) {
                if (move.category) {
                    moveHighlights.push({
                        move: state.getMove(),
                        category: move.category,
                    });

                    if (!move.category.isBest && move.bestMove) {
                        moveHighlights.push({
                            move: move.bestMove.move,
                            category: MoveCategory.BEST,
                        });
                    }
                }
            }

            boardDirectives.push(...this.boardController.createAnalysis(
                this.source.getRules(),
                moveIndex,
                state.getBoard(),
                state.getMove(),
                moveHighlights,
            ));
        } else {
            boardDirectives.push(...this.boardController.createWait(
                this.source.getRules(),
                moveIndex,
                state.getBoard(),
                subject,
                state.isFinished(),
            ));
        }

        // Create the player state directive.
        playerStateDirectives.push(...this.playerStateController.createUpdate(
            state.getLightPlayer(),
            state.getDarkPlayer(),
            subject,
        ));

        // Create the directive!
        return new GameDirective(
            this.assignNextDirectiveID(),
            state,
            boardDirectives,
            diceDirectives,
            playerStateDirectives,
        );
    }

    generateGameDirectives(moveIndex: number, moves: QuickMove[]): GameDirective[] {
        const displayMove = this.getDisplayMove();
        if (displayMove === null) {
            const state = this.game.getStates()[0];
            return [this.generateGameDirective(moveIndex, state, null, moves)];
        }

        const { rolledState, moveState, afterState } = displayMove;
        const directives: GameDirective[] = [];
        if (moves.length > 0) {
            const state = moveState ?? afterState;
            directives.push(this.generateGameDirective(moveIndex, state, null, moves));
        }

        if (moveState) {
            directives.push(this.generateGameDirective(moveIndex, moveState, rolledState, []));
        } else {
            const state = rolledState ?? afterState;
            directives.push(this.generateGameDirective(moveIndex, state, rolledState, []));
        }
        return directives;
    }

    updateMove(newMoveIndex: number, playMoves: boolean) {
        const currentMoveIndex = this.moveIndex.get();

        const moves: QuickMove[] = [];
        if (playMoves) {
            const firstMoveIndex = Math.max(0, currentMoveIndex);
            for (let moveIndex = firstMoveIndex; moveIndex < newMoveIndex; ++moveIndex) {
                const move = this.moves[moveIndex];
                if (move.moveState) {
                    moves.push({
                        moveIndex,
                        board: move.moveState.getBoard(),
                        move: move.moveState.getMove(),
                    });
                }
            }
        }

        if (newMoveIndex !== currentMoveIndex && this.moveIndex.set(newMoveIndex, currentMoveIndex)) {
            // We want to start our new directives immediately.
            this.boardController.clearNonLimboDirectives();
            this.diceController.clearNonLimboDirectives();
            this.playerStateController.clearNonLimboDirectives();
            this.pushDirectives(this.generateGameDirectives(newMoveIndex, moves));
        }
    }

    getMoveIndex(): Rune<number> {
        return this.moveIndex;
    }

    updateMoveIndex(newMoveIndex: number) {
        this.updateMove(newMoveIndex, false);
    }

    findKeyMove(startIndex: number, direction: number): number | null {
        const moves = this.analysedMoves.get();
        const player = this.getFocusedPlayer();

        let index = startIndex + direction;
        while (index >= 0 && index < moves.length) {
            const move = moves[index];
            if ((player === null || move.player === player) && move.isKeyMove)
                return index;

            index += direction;
        }
        return clamp(index, -1, moves.length - 1);
    }

    processNavigation(event: NavigationGameEvent) {
        const moveIndex = this.moveIndex.get();

        if (event.shouldJumpToKeyMoves()) {
            const keyMoveIndex = this.findKeyMove(
                moveIndex,
                (event.isForwards() ? 1 : -1),
            );
            if (keyMoveIndex !== null) {
                this.updateMove(keyMoveIndex, true);
                return;
            }
        }

        if (event.isForwards()) {
            this.updateMove(Math.min(moveIndex + 1, this.moves.length - 1), true);
        } else {
            this.updateMove(Math.max(moveIndex - 1, -1), false);
        }
    }
}
