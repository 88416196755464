import { Controller } from "@/ts/business/game/controller/Controller";
import { BoardDirective } from "./BoardDirective";
import { Board } from "@/ts/royalur/model/Board";
import { Move } from "@/ts/royalur/model/Move";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { ListenerStore } from "@/ts/business/ListenerStore";
import { NavigationGameEvent } from "@/ts/business/game/event/NavigationGameEvent";
import { MoveBoardDirective } from "@/ts/business/game/controller/board/MoveBoardDirective";
import { PlayBoardDirective } from "@/ts/business/game/controller/board/PlayBoardDirective";
import { QuickMovesBoardDirective } from "@/ts/business/game/controller/board/QuickMovesBoardDirective";
import { AnalysisBoardDirective, MoveHighlight } from "@/ts/business/game/controller/board/AnalysisBoardDirective";


type MoveListener = (event: MoveGameEvent) => void;
type NavigationListener = (event: NavigationGameEvent) => void;


export interface QuickMove {
    moveIndex: number;
    board: Board;
    move: Move;
}


/**
 * Controls the interactions with the dice user interface.
 */
export class BoardController extends Controller<BoardDirective> {
    private readonly moveListeners: ListenerStore<MoveListener>;
    private readonly navigationListeners: ListenerStore<NavigationListener>;

    constructor() {
        super();
        this.moveListeners = new ListenerStore<MoveListener>();
        this.navigationListeners = new ListenerStore<NavigationListener>();
    }

    override setup(): () => void {
        return () => {};
    }

    override onDiscardedDirectives(_directives: BoardDirective[]): void {
        // Nothing to do.
    }

    addMoveListener(moveListener: MoveListener): () => void {
        return this.moveListeners.add(moveListener);
    }

    handleMove(move: Move, skipAnimation: boolean) {
        this.moveListeners.invoke(new MoveGameEvent(move, skipAnimation));
    }

    addNavigationListener(navigationListener: NavigationListener): () => void {
        return this.navigationListeners.add(navigationListener);
    }

    handleNavigation(event: NavigationGameEvent) {
        this.navigationListeners.invoke(event);
    }

    createMakeMove(
        rules: RuleSet,
        moveIndex: number,
        board: Board,
        move: Move,
        moveWinsGame: boolean,
    ): BoardDirective[] {
        return [
            new MoveBoardDirective(
                this.assignNextDirectiveID(),
                rules, moveIndex, board, move, moveWinsGame, false,
            ),
            ...this.createWait(rules, moveIndex, board, move.getPlayer(), moveWinsGame),
        ];
    }

    createQuickMoves(
        rules: RuleSet,
        moves: QuickMove[],
    ): BoardDirective[] {
        if (moves.length <= 0)
            throw new Error("Requires at least one move");

        const moveDirectives: MoveBoardDirective[] = [];
        for (const { moveIndex, board, move } of moves) {
            moveDirectives.push(new MoveBoardDirective(
                this.assignNextDirectiveID(),
                rules, moveIndex, board, move, false, false,
            ));
        }

        const lastDir = moveDirectives[moveDirectives.length - 1];
        return [
            new QuickMovesBoardDirective(
                this.assignNextDirectiveID(),
                rules, moveDirectives,
            ),
            ...this.createWait(
                rules, lastDir.moveIndex, lastDir.board, lastDir.getActivePlayer(), false
            ),
        ];
    }

    createAnalysis(
        rules: RuleSet,
        moveIndex: number,
        board: Board,
        previewMove: Move,
        moveHighlights: MoveHighlight[],
    ): BoardDirective[] {
        const player = previewMove.getPlayer();
        return [new AnalysisBoardDirective(
            this.assignNextDirectiveID(),
            rules, moveIndex, player, board, previewMove, moveHighlights
        )];
    }

    createWait(
        rules: RuleSet,
        moveIndex: number,
        board: Board,
        activePlayer: PlayerType,
        isGameWon: boolean,
    ): BoardDirective[] {
        return [new PlayBoardDirective(
            this.assignNextDirectiveID(),
            rules, moveIndex, activePlayer, board, null, null, isGameWon,
        )];
    }

    createPromptForMove(
        rules: RuleSet,
        moveIndex: number,
        board: Board,
        roll: RoyalUrRoll,
        availableMoves: Move[],
    ): BoardDirective[] {
        if (availableMoves.length === 0)
            throw new Error("No available moves!");

        const player = availableMoves[0].getPlayer();
        return [new PlayBoardDirective(
            this.assignNextDirectiveID(),
            rules, moveIndex, player, board, roll, availableMoves, false,
        )];
    }
}
