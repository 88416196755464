import { ImageAsset } from "@/app_components/assets/ImageAsset";
import { AssetLoader } from "@/app_components/assets/AssetLoader";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardAsset } from "@/app_components/game/board/BoardAsset";
import { StandardBoardShape } from "@/ts/royalur/model/shape/StandardBoardShape";
import { RoyalUrRolledDie } from "@/ts/business/game/royalur/RoyalUrRolledDie";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { AudioAsset } from "@/app_components/assets/AudioAsset";
import { randInt } from "@/ts/util/utils";
import { GameThemeSettings } from "@/app_components/game/theme/GameThemeSettings";
import { HolidayGameThemeSettings } from "@/app_components/game/theme/HolidayGameThemeSettings";
import { StaticImageData } from "next/image";
import { clamp, min } from "@/ts/util/numbers";
import { MusicAsset } from "@/app_components/assets/MusicAsset";
import { HalloweenGameThemeSettings } from "@/app_components/game/theme/HalloweenGameThemeSettings";


export class GameTheme {
    private readonly themeType: GameThemeType;
    private readonly themeSettings: GameThemeSettings;

    private readonly lightPieceSelected: ImageAsset;
    private readonly lightPieceUnselected: ImageAsset;
    private readonly darkPieceSelected: ImageAsset;
    private readonly darkPieceUnselected: ImageAsset;

    private readonly board: ImageAsset;
    private readonly boardJSON: [number, number, number, number][];
    private readonly behindBoardBgData: StaticImageData | null;

    private readonly diceUpImages: ImageAsset[];
    private readonly diceDownImages: ImageAsset[];
    private readonly diceSelectedShadow: ImageAsset;
    private readonly diceUnselectedShadow: ImageAsset;

    private readonly pickupSound: AudioAsset;
    private readonly placeSound: AudioAsset;
    private readonly rosetteSound: AudioAsset;
    private readonly captureSound: AudioAsset;
    private readonly scoreSound: AudioAsset;
    private readonly victorySound: AudioAsset;

    private readonly errorSound: AudioAsset;
    private readonly diceSelectSounds: AudioAsset[];
    private readonly diceRollSounds: AudioAsset[];

    private readonly musicTracks: MusicAsset[];

    constructor(settings: GameThemeSettings, assetLoader: AssetLoader) {
        this.themeType = settings.themeType;
        this.themeSettings = settings;
        this.lightPieceSelected = assetLoader.loadImage(settings.lightPieceSelected);
        this.lightPieceUnselected = assetLoader.loadImage(settings.lightPieceUnselected);
        this.darkPieceSelected = assetLoader.loadImage(settings.darkPieceSelected);
        this.darkPieceUnselected = assetLoader.loadImage(settings.darkPieceUnselected);
        this.board = assetLoader.loadImage(settings.board);
        this.boardJSON = settings.boardJSON;
        this.behindBoardBgData = settings.behindBoardBgData;
        this.diceUpImages = assetLoader.loadImages(settings.diceUpImages);
        this.diceDownImages = assetLoader.loadImages(settings.diceDownImages);
        this.diceSelectedShadow = assetLoader.loadImage(settings.diceSelectedShadow);
        this.diceUnselectedShadow = assetLoader.loadImage(settings.diceUnselectedShadow);
        this.pickupSound = assetLoader.loadAudio(settings.pickupSound);
        this.placeSound = assetLoader.loadAudio(settings.placeSound);
        this.rosetteSound = assetLoader.loadAudio(settings.rosetteSound);
        this.captureSound = assetLoader.loadAudio(settings.captureSound);
        this.scoreSound = assetLoader.loadAudio(settings.scoreSound);
        this.victorySound = assetLoader.loadAudio(settings.victorySound);
        this.errorSound = assetLoader.loadAudio(settings.errorSound);
        this.diceSelectSounds = assetLoader.loadAudioSet(settings.diceSelectSounds);
        this.diceRollSounds = assetLoader.loadAudioSet(settings.diceRollSounds, { pool: 6 });
        this.musicTracks = assetLoader.loadMusicTracks(settings.tracks);
    }

    static createDefault(assetLoader: AssetLoader): GameTheme {
        return new GameTheme(new GameThemeSettings(), assetLoader);
    }

    static createHoliday(assetLoader: AssetLoader): GameTheme {
        return new GameTheme(new HolidayGameThemeSettings(), assetLoader);
    }

    static createHalloween(assetLoader: AssetLoader): GameTheme {
        return new GameTheme(new HalloweenGameThemeSettings(), assetLoader);
    }

    static create(themeType: GameThemeType, assetLoader: AssetLoader): GameTheme {
        if (themeType === GameThemeType.DEFAULT)
            return GameTheme.createDefault(assetLoader);
        if (themeType === GameThemeType.HOLIDAY)
            return GameTheme.createHoliday(assetLoader);
        if (themeType === GameThemeType.HALLOWEEN)
            return GameTheme.createHalloween(assetLoader);
        throw new Error("Unknown game theme " + themeType.getName());
    }

    getThemeType(): GameThemeType {
        return this.themeType;
    }

    getThemeSettings(): GameThemeSettings {
        return this.themeSettings;
    }

    getFireworkColour(): { r: number; g: number; b: number } {
        return this.themeSettings.getFireworkColour();
    }

    getPieceImage(playerType: PlayerType, selected: boolean): ImageAsset {
        switch (playerType) {
            case PlayerType.LIGHT:
                return (selected ? this.lightPieceSelected : this.lightPieceUnselected);
            case PlayerType.DARK:
                return (selected ? this.darkPieceSelected : this.darkPieceUnselected);
            default:
                throw new Error("Unknown player type, " + JSON.stringify(playerType));
        }
    }

    getBoardImage(): ImageAsset {
        return this.board;
    }

    createBoardAsset(): BoardAsset {
        return new BoardAsset(
            new StandardBoardShape(),
            this.board, this.boardJSON,
        );
    }

    getBehindBoardBgData(): StaticImageData | null {
        return this.behindBoardBgData;
    }

    getDiceUpImage(variation: number): ImageAsset {
        if (variation < 0 || variation > 1)
            throw new Error(`variation should be in the range [0, 1]: ${variation}`);

        const index = Math.floor(variation * this.diceUpImages.length);
        return this.diceUpImages[clamp(index, 0, this.diceUpImages.length - 1)];
    }

    getDiceDownImage(variation: number): ImageAsset {
        if (variation < 0 || variation > 1)
            throw new Error(`variation should be in the range [0, 1]: ${variation}`);

        const index = Math.floor(variation * this.diceDownImages.length);
        return this.diceDownImages[clamp(index, 0, this.diceDownImages.length - 1)];
    }

    getDiceImage(die: RoyalUrRolledDie): ImageAsset {
        const variation = die.getVariation();
        if (die.isUp()) {
            return this.getDiceUpImage(variation);
        } else {
            return this.getDiceDownImage(variation);
        }
    }

    getDiceShadow(selected: boolean) {
        return selected ? this.diceSelectedShadow : this.diceUnselectedShadow;
    }

    getPickupSound(): AudioAsset {
        return this.pickupSound;
    }

    getPlaceSound(): AudioAsset {
        return this.placeSound;
    }

    getRosetteSound() {
        return this.rosetteSound;
    }

    getCaptureSound(): AudioAsset {
        return this.captureSound;
    }

    getScoreSound() {
        return this.scoreSound;
    }

    getVictorySound(): AudioAsset {
        return this.victorySound;
    }

    getErrorSound(): AudioAsset {
        return this.errorSound;
    }

    getDiceSelectSound(diceIndex: number): AudioAsset {
        return this.diceSelectSounds[min(diceIndex, this.diceSelectSounds.length - 1)];
    }

    getDiceRollSounds(): AudioAsset[] {
        return this.diceRollSounds;
    }

    getDiceRollSound(): AudioAsset {
        return this.diceRollSounds[randInt(0, this.diceRollSounds.length)];
    }

    getMusicTracks(): MusicAsset[] {
        return this.musicTracks;
    }

    findMusicTrackToPlay(): MusicAsset | null {
        if (this.musicTracks.length === 0)
            return null;

        return this.musicTracks[randInt(0, this.musicTracks.length)];
    }
}
