import { GameDirective } from "@/ts/business/game/controller/GameDirective";
import { PlayerStateController } from "@/ts/business/game/controller/playerstate/PlayerStateController";
import { DiceController } from "@/ts/business/game/controller/dice/DiceController";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { BoardController } from "@/ts/business/game/controller/board/BoardController";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { Game } from "@/ts/royalur/Game";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GameDiff } from "@/ts/royalur/GameDiff";
import { ListenerStore } from "@/ts/business/ListenerStore";
import { ReactionController } from "@/ts/business/game/controller/reactions/ReactionController";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { GameController } from "@/ts/business/game/controller/GameController";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";


export type WinListener = () => void;


/**
 * Coordinates the interactions within a game.
 */
export class PlayGameController extends GameController {
    private readonly winListeners: ListenerStore<WinListener>;

    private displayedGame: Game;
    private stateIndex: number = 0;

    constructor(
        playerStateController: PlayerStateController,
        reactionController: ReactionController,
        diceController: DiceController,
        boardController: BoardController,
        source: GameSource<any>,
        defaultThemeType: GameThemeType,
    ) {
        super(
            playerStateController,
            reactionController,
            diceController,
            boardController,
            source,
            defaultThemeType,
        );
        this.winListeners = new ListenerStore<WinListener>();

        this.displayedGame = source.game.get();
        this.updateFromGame(this.displayedGame, new GameUpdateEvent(true));

        this.postConstructor();
    }

    override setup(): () => void {
        const cleanup: (() => void)[] = [
            super.setup(),
        ];

        cleanup.push(this.source.game.subscribe(
            (game, event) => this.updateFromGame(game, event),
        ));
        cleanup.push(this.source.addAnimateDiceRollingListener(
            () => this.handleAnimateDiceRolling(),
        ));

        cleanup.push(this.source.addReactionListener(
            (player, reaction) => this.handleReaction(player, reaction),
        ));
        cleanup.push(this.reactionController.addReactionListener(
            (player, reaction) => this.source.handleReaction(player, reaction),
        ));

        cleanup.push(this.diceController.addRollListener(
            event => this.source.handleRoll(event),
        ));

        cleanup.push(this.boardController.addMoveListener(
            event => this.source.handleMove(event),
        ));

        // There may be AI moves to make.
        this.updateFromGame(this.displayedGame, new GameUpdateEvent(false));

        return () => cleanup.forEach(fn => fn());
    }

    override addWinListener(listener: WinListener): () => void {
        return this.winListeners.add(listener);
    }

    override rematch() {
        this.source.handleRematch();
    }

    override cancelRematch() {
        this.source.cancelRematch();
    }

    override handleReaction(player: PlayerType | null, reaction: ReactionType) {
        this.reactionController.pushDirectives(
            this.reactionController.createReaction(
                player, reaction,
            ),
        );
    }

    override handleAnimateDiceRolling() {
        this.pushDirective(this.generateDiceRollingGameDirective());
    }

    private resolveNewGame(newGame: Game) {
        if (newGame === this.displayedGame)
            return;

        const diff = GameDiff.create(newGame, this.displayedGame);
        this.displayedGame = newGame;

        if (diff.hasCommonIndex()) {
            this.stateIndex = Math.min(
                diff.getCommonIndexNew() + 1,
                diff.getNewStates().length - 1,
            );
        } else {
            // Start of the game.
            if (!diff.doesPreviousContainAction()) {
                this.stateIndex = 0;
            } else {
                this.stateIndex = newGame.getStates().length - 1;
            }
        }
    }

    private updateFromGame(newGame: Game, event: GameUpdateEvent) {
        const skipAnimation = event.shouldSkipAnimation();

        // Update to the new game.
        const gameChanged = (this.displayedGame !== newGame);
        if (gameChanged) {
            this.resolveNewGame(newGame);
        }

        // Add the new states as directives.
        const states = this.displayedGame.getStates();
        const newDirectives: GameDirective[] = [];
        while (this.stateIndex < states.length) {
            const index = this.stateIndex;
            const state = states[index];
            const nextState = (index + 1 >= states.length ? null : states[index + 1]);
            this.stateIndex += 1;
            if (skipAnimation && state instanceof ActionGameState)
                continue;

            newDirectives.push(this.generateGameDirective(state, nextState));
        }
        this.pushDirectives(newDirectives);

        // Call the win listeners.
        if (newGame.isFinished()) {
            this.winListeners.invoke();
        }
    }

    generateGameDirective(
        state: GameState,
        nextState: GameState | null = null,
    ): GameDirective {
        const boardDirectives: BoardDirective[] = [];
        const diceDirectives: DiceDirective[] = [];
        const playerStateDirectives: PlayerStateDirective[] = [];

        // Create the dice directives.
        if (state instanceof WaitingForRollGameState) {
            const canInteract = this.source.isLocalHumanPlayer(state.getTurn());
            if (canInteract) {
                diceDirectives.push(...this.diceController.createWaitForRoll(
                    state.getTurn(),
                ));
            } else {
                diceDirectives.push(...this.diceController.createWait(
                    state.getTurn(),
                ));
            }
        } else if (state instanceof RolledGameState) {
            const settings = this.source.getRules().getSettings();
            diceDirectives.push(...this.diceController.createRoll(
                state.getTurn(),
                RoyalUrRoll.cast(state.getRoll()),
                state.getAvailableMoves().length === 0,
                state.getTurnPlayer().getScore() >= settings.getStartingPieceCount() - 1,
            ));
        } else {
            let roll: RoyalUrRoll | null = null;
            if (state instanceof WaitingForMoveGameState || state instanceof MovedGameState) {
                roll = RoyalUrRoll.cast(state.getRoll());
            } else {
                // Get the last dice roll from the game.
                const states = this.displayedGame.getStates();
                for (let index = this.stateIndex - 1; index >= 0; --index) {
                    const previousState = states[index];
                    if (previousState instanceof WaitingForMoveGameState
                        || previousState instanceof MovedGameState
                        || previousState instanceof RolledGameState) {
                        roll = RoyalUrRoll.cast(previousState.getRoll());
                        break;
                    }
                }
            }
            if (roll === null) {
                roll = RoyalUrRoll.createZeroed(4);
            }
            diceDirectives.push(...this.diceController.createRolled(
                state.getSubject() ?? PlayerType.LIGHT, roll,
            ));
        }

        // Create the board directives.
        if (state instanceof WaitingForMoveGameState) {
            const canInteract = this.source.isLocalHumanPlayer(state.getTurn());
            if (canInteract) {
                boardDirectives.push(...this.boardController.createPromptForMove(
                    this.source.getRules(),
                    -1, // TODO : What's the move index?
                    state.getBoard(),
                    RoyalUrRoll.cast(state.getRoll()),
                    state.getAvailableMoves(),
                ));
            } else {
                boardDirectives.push(...this.boardController.createWait(
                    this.source.getRules(),
                    -1, // TODO : What's the move index?
                    state.getBoard(),
                    state.getSubject(),
                    state.isFinished(),
                ));
            }
        } else if (state instanceof MovedGameState) {
            boardDirectives.push(...this.boardController.createMakeMove(
                this.source.getRules(),
                -1, // TODO : What's the move index?
                state.getBoard(),
                state.getMove(),
                (nextState !== null && nextState instanceof EndGameState),
            ));

            // We want pieces to subtract immediately, but we don't want
            // pieces to increase until after the move finishes.
            const piecesSource = nextState ?? state;
            playerStateDirectives.push(...this.playerStateController.createUpdate(
                new PlayerState(
                    PlayerType.LIGHT,
                    Math.min(
                        state.getLightPlayer().getPieceCount(),
                        piecesSource.getLightPlayer().getPieceCount(),
                    ),
                    state.getLightPlayer().getScore(),
                ),
                new PlayerState(
                    PlayerType.DARK,
                    Math.min(
                        state.getDarkPlayer().getPieceCount(),
                        piecesSource.getDarkPlayer().getPieceCount(),
                    ),
                    state.getDarkPlayer().getScore(),
                ),
                state.getSubject(),
            ));

        } else {
            boardDirectives.push(...this.boardController.createWait(
                this.source.getRules(),
                -1, // TODO : What's the move index?
                state.getBoard(),
                state.getSubject() ?? PlayerType.LIGHT,
                state.isFinished(),
            ));
        }

        // Create the player state directives.
        if (playerStateDirectives.length === 0) {
            playerStateDirectives.push(...this.playerStateController.createUpdate(
                state.getLightPlayer(),
                state.getDarkPlayer(),
                state.getSubject() ?? PlayerType.LIGHT,
            ));
        }

        // Create the directive!
        return new GameDirective(
            this.assignNextDirectiveID(),
            state,
            boardDirectives,
            diceDirectives,
            playerStateDirectives,
        );
    }

    generateDiceRollingGameDirective(): GameDirective {
        const state = this.source.game.get().getState();
        const template = this.generateGameDirective(state);
        const diceDirectives = this.diceController.createRoll(
            state.getSubject() ?? PlayerType.LIGHT,
            null, false, false,
        );
        return new GameDirective(
            this.assignNextDirectiveID(),
            state,
            template.boardDirectives,
            diceDirectives,
            template.playerStateDirectives,
        );
    }
}
