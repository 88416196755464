import { Directive } from "@/ts/business/game/controller/Directive";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the state of the player.
 */
export class PlayerStateDirective extends Directive {
    readonly lightPlayerState: PlayerState;
    readonly darkPlayerState: PlayerState;
    readonly activePlayer: PlayerType;

    constructor(
        directiveID: number,
        lightPlayerState: PlayerState,
        darkPlayerState: PlayerState,
        activePlayer: PlayerType,
    ) {
        super(directiveID);
        this.lightPlayerState = lightPlayerState;
        this.darkPlayerState = darkPlayerState;
        this.activePlayer = activePlayer;
    }

    isLimboDirective(): boolean {
        return true;
    }

    getLightPlayerState(): PlayerState {
        return this.lightPlayerState;
    }

    getDarkPlayerState(): PlayerState {
        return this.darkPlayerState;
    }

    getPlayerState(player: PlayerType): PlayerState {
        switch (player) {
            case PlayerType.LIGHT:
                return this.getLightPlayerState();
            case PlayerType.DARK:
                return this.getDarkPlayerState();
            default:
                throw new Error("Unknown player type " + JSON.stringify(player));
        }
    }

    equals(other: PlayerStateDirective): boolean {
        return this.lightPlayerState.equals(other.lightPlayerState)
            && this.darkPlayerState.equals(other.darkPlayerState);
    }
}
