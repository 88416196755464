import { Directive } from "@/ts/business/game/controller/Directive";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the dice.
 */
export abstract class BoardDirective extends Directive {
    readonly rules: RuleSet;
    readonly moveIndex: number;

    constructor(
        directiveID: number,
        rules: RuleSet,
        moveIndex: number,
    ) {
        super(directiveID);
        this.rules = rules;
        this.moveIndex = moveIndex;
    }

    abstract getBoard(): Board;

    abstract getActivePlayer(): PlayerType;
}
