import {
    ControllerProviderType,
    createControllerContext,
    useCreateController,
} from "@/app_components/game/createControllerContext";
import { createContext, ReactNode, useContext } from "react";
import { DiceControllerProvider, useDiceController } from "@/app_components/game/dice/DiceUIContext";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { GameController } from "@/ts/business/game/controller/GameController";
import { GameDirective } from "@/ts/business/game/controller/GameDirective";
import { PlayerStateControllerProvider, usePlayerStateController } from "@/app_components/game/playerstate/PlayerStateUIContext";
import { BoardControllerProvider, useBoardController } from "@/app_components/game/board/BoardUIContext";
import { AgentThread } from "@/app_components/game/agent/AgentThread";
import { GameMode } from "@/ts/business/game/GameMode";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";
import { LocalGameSource } from "@/ts/business/game/controller/source/LocalGameSource";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { Agent } from "@/ts/royalur/agent/Agent";
import { ComputerGameSource } from "@/ts/business/game/controller/source/ComputerGameSource";
import { OnlineGameSource } from "@/ts/business/game/controller/source/OnlineGameSource";
import { useRune } from "@/app_util/useRune";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { matomo } from "@/app_components/Matomo";
import { GameThemeProvider } from "@/app_components/game/theme/GameThemeContext";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { APIGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionControllerProvider, useReactionController } from "@/app_components/game/reactions/ReactionUIContext";
import { RemoteLobbySettings } from "@/ts/business/game/RemoteLobbySettings";
import { useIsEpochTimeInFuture } from "@/app_util/time/useIsEpochTimeInFuture";
import { Connection } from "@/ts/business/game/server/Connection";
import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { BotLobbyPlayer } from "@/ts/business/lobby/BotLobbyPlayer";
import { AnalysisGameSource } from "@/ts/business/game/controller/source/AnalysisGameSource";
import { AnalysisGameController } from "@/ts/business/game/controller/AnalysisGameController";
import { PlayGameController } from "@/ts/business/game/controller/PlayGameController";
import { readStorage } from "@/ts/util/storage";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";


/**
 * Used to pass the game settings to the game controller...
 */
export const GameSourceContext = createContext<
    GameSource<any> | null
>(null);

export function useGameSource(): GameSource<any> {
    const settings = useContext(GameSourceContext);
    if (settings === null)
        throw new Error("Game setup settings are unavailable!");

    return settings;
}


/**
 * Used to pass the game settings to the game controller...
 */
export const GameClientControlsContext = createContext<
    GameClientControls | null
>(null);

export function useGameClientControls(): GameClientControls {
    const controls = useContext(GameClientControlsContext);
    if (controls === null)
        throw new Error("Game client controls are unavailable!");

    return controls;
}


export function createGameSource(
    user: APIUser | null,
    settings: LobbySettings,
    initialClientControls: GameClientControls,
    initialPreferences: APIGamePreferences,
): GameSource<any> {
    if (settings instanceof RemoteLobbySettings) {
        const name = "";
        return new OnlineGameSource(
            initialClientControls, initialPreferences,
            matomo, webGameAPI,
            settings, settings.getLobbyID(), name,
        );
    }
    if (!settings.isAvailable())
        throw new Error("Expected game settings to be available");

    const ruleProvider = new SimpleRuleSetProvider();
    const rules = ruleProvider.create(settings.getGameSettings());

    if (settings.getMode() === GameMode.COMPUTER) {
        const difficulty = settings.getBotType();
        let agent: Agent;
        if (difficulty.isExpensive()) {
            agent = new AgentThread(difficulty);
        } else {
            agent = difficulty.createAgent(rules);
        }
        return new ComputerGameSource(
            initialClientControls, initialPreferences,
            user, matomo, webGameAPI, settings,
            rules, difficulty, agent,
        );
    }

    if (settings.getMode() === GameMode.LOCAL) {
        return new LocalGameSource(
            initialClientControls, initialPreferences,
            matomo, webGameAPI, settings, rules,
        );
    }
    throw new Error(`Unknown game mode ${settings.getMode().getName()}`);
}


function readDefaultThemeType(): GameThemeType {
    const disabledThemeType = GameThemeType.getByNameOrNull(readStorage("disabled-theme-type"));

    const now = new Date();
    for (const themeType of GameThemeType.values()) {
        if (themeType === disabledThemeType)
            continue;

        if (themeType.isInDateRange(now))
            return themeType;
    }
    return GameThemeType.DEFAULT;
}


/**
 * Manages the game user interface and directives.
 */
function createGameControllerContext(): {
    GameControllerProvider: ControllerProviderType;
    useGameController: () => GameController;
    useOptionalGameController: () => GameController | null;
    useGameDirective: () => GameDirective;
} {
    const useCreateGameController = () => {
        const gameSource = useGameSource();
        const playerStateController = usePlayerStateController();
        const reactionController = useReactionController();
        const diceController = useDiceController();
        const boardController = useBoardController();

        const defaultThemeType = readDefaultThemeType();

        const controller = useCreateController<GameDirective, GameController>(() => {
            if (gameSource instanceof AnalysisGameSource) {
                return new AnalysisGameController(
                    playerStateController, reactionController,
                    diceController, boardController, gameSource,
                    defaultThemeType,
                );
            } else {
                return new PlayGameController(
                    playerStateController, reactionController,
                    diceController, boardController, gameSource,
                    defaultThemeType,
                );
            }
        }, [
            playerStateController, reactionController,
            diceController, boardController, gameSource,
        ]);

        return controller;
    };

    const {
        ControllerProvider, useController, useOptionalController, useDirective,
    } = createControllerContext<GameDirective, GameController>(useCreateGameController);

    return {
        GameControllerProvider: ControllerProvider,
        useGameController: useController,
        useOptionalGameController: useOptionalController,
        useGameDirective: useDirective,
    };
}

export const {
    GameControllerProvider, useGameController, useOptionalGameController, useGameDirective,
} = createGameControllerContext();


export function usePlayGameController(): PlayGameController {
    const controller = useGameController();
    if (controller instanceof PlayGameController)
        return controller;

    throw new Error("Current controller is not a PlayGameController");
}


export function useAnalysisGameController(): AnalysisGameController {
    const controller = useGameController();
    if (controller instanceof AnalysisGameController)
        return controller;

    throw new Error("Current controller is not an AnalysisGameController");
}


export function useIsPlayerConnected(lobbyStatus: LobbyStatus, player: LobbyPlayer): boolean {
    const gameData = player.getGameData();
    const lastPingTimeMs = (
        gameData ? lobbyStatus.getPlayerLastPingTimeMs(gameData.getPlayerNo()) : 0
    );
    const hasRecentPing = useIsEpochTimeInFuture(lastPingTimeMs + Connection.DISCONNECT_TIME_MS);
    return (
        !gameData || player instanceof BotLobbyPlayer
        || !lobbyStatus.hasPingTimes() || hasRecentPing
    );
}


export function useArePlayersConnected(lobbyStatus: LobbyStatus, players: GamePlayers): {
    p1Connected: boolean;
    p2Connected: boolean;
} {
    const p1Connected = useIsPlayerConnected(lobbyStatus, players.getPlayer1());
    const p2Connected = useIsPlayerConnected(lobbyStatus, players.getPlayer2());
    return { p1Connected, p2Connected };
}


/**
 * Includes the dependencies required for the game controller.
 */
function GameDependenciesProvider({ children }: { children: ReactNode }) {
    return (
        <BoardControllerProvider>
            <PlayerStateControllerProvider>
                <ReactionControllerProvider>
                    <DiceControllerProvider>
                        {children}
                    </DiceControllerProvider>
                </ReactionControllerProvider>
            </PlayerStateControllerProvider>
        </BoardControllerProvider>
    );
}


export interface GameControllerThemeProps {
    children: ReactNode;
}

function GameControllerThemeProvider({ children }: GameControllerThemeProps) {
    const controller = useGameController();
    const themeType = useRune(controller.getTheme());
    return (
        <GameThemeProvider themeType={themeType}>
            {children}
        </GameThemeProvider>
    );
}


export interface GameContextProps {
    gameSource: GameSource<any>;
    clientControls: GameClientControls;
    children: ReactNode;
}

/**
 * Includes the game controller and all its dependencies.
 */
export function GameUIContext({ gameSource, clientControls, children }: GameContextProps) {
    return (
        <GameSourceContext.Provider value={gameSource}>
            <GameClientControlsContext.Provider value={clientControls}>
                <GameDependenciesProvider>
                    <GameControllerProvider>
                        <GameControllerThemeProvider>
                            {children}
                        </GameControllerThemeProvider>
                    </GameControllerProvider>
                </GameDependenciesProvider>
            </GameClientControlsContext.Provider>
        </GameSourceContext.Provider>
    );
}
