import { Directive } from "@/ts/business/game/controller/Directive";
import { max } from "@/ts/util/numbers";
import { ListenerStore } from "@/ts/business/ListenerStore";


export type DirectiveListener<D extends Directive> = (directive: D) => void;


/**
 * Controls directives to give to rendered app_components.
 * <p>
 * External systems trigger the controller to issue directives.
 * The directives are then passed one-at-a-time to the rendered
 * app_components to be displayed/animated.
 */
export abstract class Controller<D extends Directive> {
    static readonly DEFAULT_MAX_DIRECTIVES = 10;

    private readonly maxDirectives: number;
    private readonly directiveListeners: ListenerStore<DirectiveListener<D>>;
    private readonly directives: D[];
    private nextDirectiveID: number;

    constructor(maxDirectives?: number) {
        if (maxDirectives === undefined) {
            maxDirectives = Controller.DEFAULT_MAX_DIRECTIVES;
        } else if (maxDirectives < 0) {
            throw new Error("Max directives cannot be less than zero");
        }

        this.directiveListeners = new ListenerStore<DirectiveListener<D>>();
        this.maxDirectives = maxDirectives;
        this.directives = [];
        this.nextDirectiveID = 1;
    }

    getListenerCount(): number {
        return this.directiveListeners.length();
    }

    /**
     * Called when this controller is put into use, so that it
     * can set up any listeners or callbacks that it needs.
     * Returns a cleanup for those listeners/callbacks.
     */
    abstract setup(): () => void;

    /**
     * Called when directives are discarded.
     */
    abstract onDiscardedDirectives(directives: D[]): void;

    subscribeToActiveDirective(listener: DirectiveListener<D>): () => void {
        return this.directiveListeners.add(listener);
    }

    private callActiveDirectiveCallbacks() {
        this.directiveListeners.invoke(this.getActiveDirective());
    }

    protected assignNextDirectiveID(): number {
        const directiveID = this.nextDirectiveID;
        this.nextDirectiveID += 1;
        return directiveID;
    }

    discardDirectives(directives: D[]): void {
        // We want to support an empty controller.
        const initialActive: D | null = (this.directives.length === 0 ? null : this.directives[0]);

        const discarded: D[] = [];
        for (let index = this.directives.length - 1; index >= 0; --index) {
            const directive = this.directives[index];

            let discard = false;
            for (const checkDirective of directives) {
                if (directive.getDirectiveID() === checkDirective.getDirectiveID()) {
                    discard = true;
                    break;
                }
            }

            if (discard) {
                this.directives.splice(index, 1);
                discarded.push(directive);
            }
        }
        if (discarded.length > 0) {
            this.onDiscardedDirectives(discarded);
        }

        // If the active directive changed, call the callback!
        const newActive = this.getActiveDirective();
        if (initialActive !== newActive) {
            this.callActiveDirectiveCallbacks();
        }
    }

    clearNonLimboDirectives() {
        const toClear: D[] = [];
        for (const dir of this.directives) {
            if (dir.isLimboDirective())
                continue;

            toClear.push(dir);
        }
        this.discardDirectives(toClear);
    }

    private pushDirectiveWithoutCallbacks(directive: D): D | null {
        // Sanity check.
        for (const dir of this.directives) {
            if (dir.getDirectiveID() === directive.getDirectiveID())
                throw new Error("Pushed directive twice");
        }

        // Collapse limbo directives.
        const directives = this.directives;
        while (directives[directives.length - 1]?.isLimboDirective()) {
            directives.splice(directives.length - 1, 1);
        }

        // Push the new directive!
        this.directives.push(directive);

        // Discard directives if there are too many to play.
        let discarded: D | null = null;
        if (this.maxDirectives > 0 && this.directives.length > this.maxDirectives) {
            const shifted = this.directives.shift();
            if (!shifted)
                throw new Error("Attempt to discard directive resulted in undefined");

            discarded = shifted;
        }
        return discarded;
    }

    pushDirectives(directives: D[]): void {
        // We may not have an active directive to begin with.
        const initialActive: D | null = (this.directives.length === 0 ? null : this.directives[0]);

        // Process the new directives!
        const discarded: D[] = [];
        for (const directive of directives) {
            const discardedDirective = this.pushDirectiveWithoutCallbacks(directive);
            if (discardedDirective) {
                discarded.push(discardedDirective);
            }
        }
        if (discarded.length > 0) {
            this.onDiscardedDirectives(discarded);
        }

        // If the active directive changed, call the callback!
        const newActive = this.getActiveDirective();
        if (initialActive !== newActive) {
            this.callActiveDirectiveCallbacks();
        }
    }

    pushDirective(directive: D): void {
        this.pushDirectives([directive]);
    }

    getActiveDirective(): D {
        if (this.directives.length === 0)
            throw new Error("This controller is empty!");
        return this.directives[0];
    }

    popActiveDirective(directive: D): boolean;
    popActiveDirective(directiveID: number): boolean;
    popActiveDirective(directiveOrID: D | number): boolean {
        let directiveID;
        if (directiveOrID instanceof Directive) {
            directiveID = directiveOrID.getDirectiveID();
        } else {
            directiveID = directiveOrID;
        }

        const activeDirective = this.getActiveDirective();
        if (activeDirective.getDirectiveID() !== directiveID)
            return false;

        this.directives.shift();
        this.callActiveDirectiveCallbacks();
        return true;
    }

    getQueuedDirectiveCount(): number {
        return max(0, this.directives.length - 1);
    }

    isActiveOrQueued(directive: D): boolean {
        for (const queuedDirective of this.directives) {
            if (queuedDirective === directive)
                return true;
        }
        return false;
    }
}
